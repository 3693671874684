'use client';
import 'client-only';

import { useContext } from 'react';
import { ApiClientContext } from './ApiClientContext';
import { INTERNAL__problemsImportQueryConfig } from '@features/backoffice/problems-import/_problemsImportQueryConfig';
import { INTERNAL__bankTransactionsQueryConfig } from '@features/backoffice/accounting/transactions/api/_bankTransactionsQueryConfig';

function useApiClient() {
	const {
		authApiClient,
		webshopApiClient,
		frontofficeApiClient,
		backofficeApiClient,
		sharedApiClient,
		webhooksApiClient,
		session,
	} = useContext(ApiClientContext);

	if (
		!authApiClient ||
		!webshopApiClient ||
		!frontofficeApiClient ||
		!backofficeApiClient ||
		!sharedApiClient ||
		!webhooksApiClient
	) {
		throw new Error('useApiClient must be used within ApiClientProvider');
	}

	const queries = {
		problemImports: INTERNAL__problemsImportQueryConfig(backofficeApiClient),
		bankTransactions: INTERNAL__bankTransactionsQueryConfig(backofficeApiClient),
	};

	return {
		authApiClient,
		webshopApiClient,
		frontofficeApiClient,
		backofficeApiClient,
		sharedApiClient,
		webhooksApiClient,
		session,
		queries,
	};
}

export default useApiClient;
