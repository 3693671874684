import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
	BackofficeApi,
	type BackofficeApiGetProblemsImportCandidateListRequest,
	type BackofficeApiGetProblemsImportCandidateRequest,
	type BackofficeApiGetProblemsImportImageListRequest,
	type BackofficeApiGetProblemsImportImageRequest,
} from '@features/api/client';

export const INTERNAL__problemsImportQueryConfig = (apiClient: BackofficeApi) =>
	createQueryKeys('problems-import', {
		images: {
			queryKey: ['images'],
			contextQueries: {
				list: (body: BackofficeApiGetProblemsImportImageListRequest) => ({
					queryKey: ['list', ...Object.values(body)],
					queryFn: () => {
						return apiClient.getProblemsImportImageList(body).then((res) => res.data);
					},
				}),
				single: (body: BackofficeApiGetProblemsImportImageRequest) => ({
					queryKey: ['single', ...Object.values(body)],
					queryFn: () => {
						return apiClient.getProblemsImportImage(body).then((res) => res.data);
					},
				}),
			},
		},
		candidates: {
			queryKey: ['candidates'],
			contextQueries: {
				list: (body: BackofficeApiGetProblemsImportCandidateListRequest) => ({
					queryKey: ['list', ...Object.values(body)],
					queryFn: () => {
						return apiClient.getProblemsImportCandidateList(body).then((res) => res.data);
					},
				}),
				single: (body: BackofficeApiGetProblemsImportCandidateRequest) => ({
					queryKey: ['single', ...Object.values(body)],
					queryFn: () => {
						return apiClient.getProblemsImportCandidate(body).then((res) => res.data);
					},
				}),
			},
		},
	});
